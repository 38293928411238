<template>
  <div class="walk_into" @click="handle___">
    <div class="ad">
      <img src="@/assets/img/ad.png" />
    </div>

    <div class="session nav-box">
      <div class="session-content nav">
        <div :class="idx != 1 ? 'nav-item':'nav-item current'" @click="handle_tab('1')">公司概况</div>
        <div :class="idx != 2 ? 'nav-item':'nav-item current'" @click="handle_tab('2')">经营理念</div>
        <div :class="idx != 3 ? 'nav-item':'nav-item current'" @click="handle_tab('3')">大事记</div>
        <div :class="idx != 4 ? 'nav-item':'nav-item current'" @click="handle_tab('4')">分支机构</div>
        <div :class="idx != 5 ? 'nav-item':'nav-item current'" @click="handle_tab('5')">信息公示</div>
        <div :class="idx != 6 ? 'nav-item':'nav-item current'" @click="handle_tab('6')">诚聘精英</div>
      </div>
    </div>

    <div class="session">
      <div class="session-content company-desc">
        <h1 class="common-title">公司概况</h1>
        <div class="company-centent-box">
          <div class="company-intro">
            恒银期货有限公司隶属于河北省国资委，于1999年9月21日在原秦皇岛恒银期货经纪公司基础上重新注册成立的，是河北省唯一一家法人期货公司，注册资本为2.47亿元。<br>
            　　拥有中国金融期货交易所、郑州商品交易所、大连商品交易所、广州期货交易所、上海期货交易所以及上海国际能源交易中心会员资格和席位，从事商品期货经纪、金融期货经纪及投资咨询业务。<br>
            　　公司下设综合事务部、党群工作部、投资咨询部、客户服务中心、交易风控部、结算交割部、合规管理部等多个职能部门，同时在全国各地区设立多家分公司和营业部，服务面向全国客户。<br>
            　　公司历经二十余年的发展，努力发挥期货市场的功能作用，服务实体经济，建立以市场为导向的全新客户关系管理体系和完善的风险监控体系，全心全意维护客户的利益。随着期货行业快速发展，稳步推进创新业务，依托多年的服务经验，专注于研究分析、套保套利方案设计、投资咨询等服务，为投资者提供丰富的资产保值增值全新平台和渠道。
          </div>
          <img src="@/assets/img/com.png" />
        </div>
      </div>
    </div>

    <div class="session management_idea">
      <div class="session-content management">
        <h1 class="common-title">经营理念</h1>
        <div class="ideas">
          <div class="idea-item">
            <img src="@/assets/img/idea1.png" />
          </div>
          <div class="idea-item">
            <img src="@/assets/img/idea2.png" />
          </div>
          <div class="idea-item">
            <img src="@/assets/img/idea3.png" />
          </div>
          <div class="idea-item">
            <img src="@/assets/img/idea4.png" />
          </div>
        </div>
      </div>
    </div>

    <div class="session">
      <div class="session-content big-thing">
        <h1 class="common-title">恒银大事记</h1>
        <div class="big-box">
          <div class="line">
            <div class="the-thing-circle">
              <img src="@/assets/img/current_circle.png" />
            </div>
            <div class="the-thing-circle">
              <img src="@/assets/img/circle.png" />
            </div>
            <div class="the-thing-circle">
              <img src="@/assets/img/circle.png" />
            </div>
            <div class="the-thing-circle">
              <img src="@/assets/img/circle.png" />
            </div>
            <div class="the-thing-circle">
              <img src="@/assets/img/circle.png" />
            </div>
            <div class="the-thing-circle">
              <img src="@/assets/img/circle.png" />
            </div>
            <div class="the-thing-circle">
              <img src="@/assets/img/circle.png" />
            </div>
            <div class="the-thing-circle">
              <img src="@/assets/img/circle.png" />
            </div>
            <div class="the-thing-circle">
              <img src="@/assets/img/circle.png" />
            </div>
            <div class="the-thing-circle">
              <img src="@/assets/img/circle.png" />
            </div>
            <div class="the-thing-circle">
              <img src="@/assets/img/circle.png" />
            </div>
            <div class="the-thing-circle">
              <img src="@/assets/img/circle.png" />
            </div>
            <div class="big-absolute">
              <div class="the-thing">
                <div class="the-thing-item left"></div>
                <div class="the-thing-item right">
                  <div class="the-thing-item-line"></div>
                  <span class="thing-year">2023年</span>
                  <span class="thing-title">
                    武汉营业部成立
                  </span>
                </div>
              </div>
              <div class="the-thing">
                <div class="the-thing-item left">
                  <span class="thing-title">
                    公司名称变更为“恒银期货有限公司”
                  </span>
                  <span class="thing-year">2021年</span>
                  <div class="the-thing-item-line"></div>
                </div>
                <div class="the-thing-item right"></div>
              </div>
              <div class="the-thing">
                <div class="the-thing-item left"></div>
                <div class="the-thing-item right">
                  <div class="the-thing-item-line"></div>
                  <span class="thing-year">2020年</span>
                  <span class="thing-title">
                    公司注册资本2.47亿元<br />
                    福建分公司成立<br />
                    深圳分公司成立<br />
                    山东分公司成立
                  </span>
                </div>
              </div>
              <div class="the-thing">
                <div class="the-thing-item left">
                  <span class="thing-title">
                    公司注册资本为2.32亿元<br />
                    获得期货投资咨询业务资格
                  </span>
                  <span class="thing-year">2019年</span>
                  <div class="the-thing-item-line"></div>
                </div>
                <div class="the-thing-item right"></div>
              </div>
              <div class="the-thing">
                <div class="the-thing-item left"></div>
                <div class="the-thing-item right">
                  <div class="the-thing-item-line"></div>
                  <span class="thing-year">2017年</span>
                  <span class="thing-title">
                    注册资本增加到7600万元人民币
                  </span>
                </div>
              </div>
              <div class="the-thing">
                <div class="the-thing-item left">
                  <span class="thing-title"> 注册资本增加到5800万元 </span>
                  <span class="thing-year">2013年</span>
                  <div class="the-thing-item-line"></div>
                </div>
                <div class="the-thing-item right"></div>
              </div>
              <div class="the-thing">
                <div class="the-thing-item left"></div>
                <div class="the-thing-item right">
                  <div class="the-thing-item-line"></div>
                  <span class="thing-year">2009年</span>
                  <span class="thing-title"> 沧州营业部成立 </span>
                </div>
              </div>
              <div class="the-thing">
                <div class="the-thing-item left">
                  <span class="thing-title"> 邯郸营业部成立 </span>
                  <span class="thing-year">2008年</span>
                  <div class="the-thing-item-line"></div>
                </div>
                <div class="the-thing-item right"></div>
              </div>
              <div class="the-thing">
                <div class="the-thing-item left"></div>
                <div class="the-thing-item right">
                  <div class="the-thing-item-line"></div>
                  <span class="thing-year">2007年</span>
                  <span class="thing-title">
                    获得金融期货经纪业务资格 <br />唐山营业部成立
                  </span>
                </div>
              </div>
              <div class="the-thing">
                <div class="the-thing-item left">
                  <span class="thing-title"> 注册资本增加到5000万元 </span>
                  <span class="thing-year">2006年</span>
                  <div class="the-thing-item-line"></div>
                </div>
                <div class="the-thing-item right"></div>
              </div>
              <div class="the-thing">
                <div class="the-thing-item left"></div>
                <div class="the-thing-item right">
                  <div class="the-thing-item-line"></div>
                  <span class="thing-year">2004年</span>
                  <span class="thing-title"> 承德营业部成立 </span>
                </div>
              </div>
              <div class="the-thing">
                <div class="the-thing-item left">
                  <span class="thing-title">
                    河北恒银期货经纪有限公司成立
                  </span>
                  <span class="thing-year">1999年</span>
                  <div class="the-thing-item-line"></div>
                </div>
                <div class="the-thing-item right"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      idx:1
    }
  },
  mounted(){
    this.handle_scrollTop();
  },
  methods: {
    handle_scrollTop () {
      this.$("html,body").animate({ scrollTop: 0 }, 500); //回到顶端
    },
    handle___(){
        console.log(document.documentElement.scrollTop)
    },
    handle_tab(data){
      this.idx = data
      if(data == 1){
        this.$("html,body").animate({ scrollTop: 505 }, 500); //回到顶端
      }else if(data == 2){
        this.$("html,body").animate({ scrollTop: 1170 }, 500); //回到顶端
      }else if(data == 3){
        this.$("html,body").animate({ scrollTop: 1644 }, 500); //回到顶端
      }else if(data == 4){
        this.$router.push({
          path: "/article_list",
          query: {
            column_id: '1460440529895456',
            id: '1458834147115040'
          },
        });
      }else if (data == 5){
        this.$router.push({
          path: "/article_list",
          query: {
            column_id: '1457937098735648',
            id: '1458500293099552'
          },
        });
      }else if (data == 6){
        this.$router.push({
          path: "/article_list",
          query: {
            column_id: '1460490475667488',
            id: '1469170690555938'
          },
        });
      }
    }
  }
}
</script>
<style scoped>
.session {
  width: 100%;
  display: flex;
  justify-content: center;
}

.session-content {
  width: 1200px;
}

.ad {
  width: 100%;
}

.ad img {
  width: 100%;
  height: auto;
}

.nav-box {
  height: 66px;
  background: #f2f2f2;
}

.nav {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 100px;
  justify-content: space-between;
}

.nav-item {
  height: 66px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}

.nav-item.current {
  border-bottom: 3px solid #c21d14;
}

.company-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1.common-title {
  width: 55px;
  border-bottom: 5px solid #c21d14;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 15px 0;
  margin-top: 52px;
}

.company-centent-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  padding-bottom: 70px;
}

.company-centent-box > img {
  width: 506px;
  height: auto;
}

.company-intro {
  width: 653px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 37px;
  text-indent: 2em;
}

.management_idea {
  background: #f2f2f2;
}

.management {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ideas {
  width: 100%;
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 70px;
}

.idea-item {
  width: 270px;
  height: auto;
}

.idea-item:hover {
  opacity: 0.8;
}

.idea-ite > img {
  width: 100%;
  height: auto;
}

.big-thing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.big-box {
  width: 100%;
  display: flex;
  margin-top: 80px;
}

.big-box .line {
  width: 2px;
  height: 784px;
  background: #e5e5e5;
  margin: 0 auto;
  position: relative;
}

.big-absolute {
  width: 1200px;
  position: absolute;
  top: 35px;
  left: -597px;
}

.the-thing {
  display: flex;
  align-items: center;
  height: 66px;
}

.the-thing-item {
  width: 50%;
  display: flex;
  align-items: center;
}

.the-thing-item.left {
  justify-content: flex-end;
}

.thing-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.thing-year {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c21d14;
}

.the-thing-item.left .thing-year {
  margin-left: 17px;
}

.the-thing-item.right .thing-year {
  margin-right: 17px;
}

.the-thing-item-line {
  width: 80px;
  height: 1px;
  background: #e9e9e9;
}

.the-thing-item.left .the-thing-item-line {
  margin-left: 9px;
  margin-right: 15px;
}

.the-thing-item.right .the-thing-item-line {
  margin-right: 9px;
  margin-left: 11px;
}

.the-thing-circle {
  width: 20px;
  height: 20px;
  margin-top: 46px;
  margin-left: -10px;
  z-index: 999;
}

.the-thing-circle:first-child {
  margin-top: 60px;
}

.the-thing-item.left .the-thing-circle {
  margin-left: 4px;
}

.the-thing-item.right .the-thing-circle {
  margin-left: 4px;
}

.the-thing-circle > img {
  width: 20px;
  height: 20px;
}
</style>
